module.exports = {
  siteTitle: 'Transylvania Meat Co', // <title>
  manifestName: 'Meat Co.',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#34070B',
  manifestThemeColor: '#34070B',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/l.png',
  pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Transylvania Meat Co.',
  subHeading: 'Family Owned since 2016',
  // social
  socialLinks: [
    /*{
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com',
    },*/
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/pages/category/Meat-Wholesaler/Transylvania-Meat-Co-Inc-106455394533237/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Transylvaniameatco@gmail.com',
      url: 'mailto:Transylvaniameatco@gmail.com',
    },
  ],
  phone: '+1 847-227-9627',
  address: '7518 St Louis Ave, Skokie, IL 60076',
};
