import React from 'react';
import { Link } from 'gatsby';

export default function Nav({ onMenuToggle = () => {} }) {
  return (
    <nav id="menu">
      <div className="inner">
        <h2>Menu</h2>
        <ul className="links">
          
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/About"
            >
              About Us
            </Link>
            
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/About#locations"
            >
              Locations
            </Link>
          </li>
          
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/Tips"
            >
              Tips & Tricks
            </Link>
          </li>
          <li>
          <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/Testimonials"
            >
              Testimonials
            </Link>
          </li>
          <li>
          <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#footer"
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <a
          className="close"
          onClick={e => {
            e.preventDefault();
            onMenuToggle();
          }}
          href="#menu"
        >
          {''}
        </a>
      </div>
    </nav>
  );
}
